import React, { FC } from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import {
  Frontmatter,
  TemplateProps
} from './template-types'

import {
  Breadcrumb,
  BreadcrumbItem,
  PageTitle
} from '@bscs-dev-team/bscs-design-system-core'

import Layout from '../components/layout/layout'
import SEO from '../components/seo'
import TemplateContentRow from '../components/template-content-row'

const EducatorResourceCenterTemplate: FC<TemplateProps> = ({ data }: TemplateProps) => {
  const resource: Frontmatter = data.mdx.frontmatter
  const html: unknown = data.mdx.body

  return (
    <React.Fragment>
      <SEO
        title={resource.title}
        canonicalUrl={resource.canonicalUrl}
        description={resource.seoDescription}
        lang={resource.seoLang}
      />
      <Layout>
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link className='inline-anchor' to='/'>Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              Resources
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link className='inline-anchor' to='/resources/educator-resource-center'>Educator Resource Center</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              {resource.title}
            </BreadcrumbItem>
          </Breadcrumb>
          <div className="flex bg-bscs-indigo-800 rounded mt-2">
            { resource.template === 'Image Right' &&
              <div className="w-1/2 h-full self-center text-center">
                <PageTitle>
                  <div className="flex w-full justify-center mb-2">
                    <div className="border-b-4 border-bscs-yellow-300 w-16 -ml-16 rounded" />
                  </div>
                  <span className="text-bscs-gray-100">
                    { resource.title }
                  </span>
                  <div className="flex w-full justify-center mt-2">
                    <div className="border-t-4 border-bscs-orange-500 w-16 ml-16 rounded" />
                  </div>
                </PageTitle>
              </div>
            }
            <Img
              alt={resource.alt}
              className="w-1/2 h-auto rounded-l"
              fluid={resource.image.childImageSharp.fluid}
            />
            { resource.template === 'Image Left' &&
              <div className="w-1/2 h-full self-center text-center">
                <PageTitle>
                  <div className="flex w-full justify-center mb-2">
                    <div className="border-b-4 border-bscs-yellow-300 w-16 -ml-16 rounded" />
                  </div>
                  <span className="text-bscs-gray-100">
                    { resource.title }
                  </span>
                  <div className="flex w-full justify-center mt-2">
                    <div className="border-t-4 border-bscs-orange-500 w-16 ml-16 rounded" />
                  </div>
                </PageTitle>
              </div>
            }
          </div>
          <TemplateContentRow resource={ resource } html={ html } />
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default EducatorResourceCenterTemplate

export const data = graphql`
  query($nodeId: String!) {
    mdx(id: {eq: $nodeId}) {
      body
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        additionalTags
        alt
        image {
          childImageSharp {
            fluid(maxWidth: 800, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        areas
        discipline
        gradeLevel
        programLength
        canonicalUrl
        seoDescription
        seoLang
        sidebarCards {
          cardText
          cardTitle
          contacts {
            infoat
            person
          }
          form {
            type
            sendTo
          }
          image {
            childImageSharp {
              fluid(maxWidth: 500, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          imageAlt
          urls {
            buttonText
            external
            url
            disabled
          }
        }
        template
        title
      }
    }
  }
`
